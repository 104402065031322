<template>
 <hcc-modal
    :title="$t('reports.modal-client')"
    name="download-conversation"
    @confirm="requestReport"
    :confirmDisableButton="!enableConfirm"
  >
    <label class="reports__time">{{ $t('messages.client')}}</label>
    <div class="report__head"> {{ client.name }}</div>
    <label class="reports__time">{{ $t('reports.range') }}</label>
    <input-date ref="schedule"
      class="select"
      :options="{ opens: 'right' }"
      :startDate="currentStartDate"
      :endDate="currentEndDate"
      @update="selectRange"
    />
  </hcc-modal>
</template>

<script>
import { mapMutations } from 'vuex';
import HccModal from '@/components/shared/HccModal/index.vue';
import createReport from '@/graphql/mutations/createReport.gql';
import { REPORT_MESSAGES_REQUEST } from '@/eventTypes';
import EventBus from '@/eventBus';

export default {
  props: {
    client: {
      type: Object,
      required: true,
      default: () => {},
    },
    campaign: {
      type: String,
      required: true,
    },
    company: {
      type: String,
      required: true,
    },
  },
  components: {
    HccModal,
    InputDate: () => import('@/components/InputDate.vue'),
  },
  data() {
    return {
      currentStartDate: null,
      currentEndDate: null,
    };
  },
  computed: {
    enableConfirm() {
      return !!this.currentStartDate && !!this.currentEndDate;
    },
  },
  watch: {
    client() {
      this.currentStartDate = null;
      this.currentEndDate = null;
    },
  },
  methods: {
    ...mapMutations('reports', ['addReport']),
    async requestReport() {
      const query = {
        company: this.company,
        campaign: this.campaign,
        start: this.currentStartDate,
        end: this.currentEndDate,
        type: 'ConversationClientReport',
        client: this.client.id,
      };

      const { data } = await this.$apollo.mutate({
        mutation: createReport,
        variables: query,
      });

      this.addReport(data.createReport);
      EventBus.$emit(REPORT_MESSAGES_REQUEST);
    },
    selectRange(startDate, endDate) {
      this.currentStartDate = startDate;
      this.currentEndDate = endDate;
    },
  },
};
</script>

<style scoped lang="scss">

  .report__head {
    width: 100%;
    margin: 10px 15px 25px 0;
    font-size: 15px;
    border-bottom: 2px solid $gray-super-light;;
    padding: 5px;
  }
  @import '~styles/components/_generate-report.scss';
</style>
